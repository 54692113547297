import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../pages/Home')
    },
    {
        path: '/art-pieces',
        name: 'ArtGalery',
        component: () => import('../pages/ArtGalery')
    },
    {
        path: '/cuadros',
        name: 'ArtGalery',
        component: () => import('../pages/ArtGalery')
    },
    {
        path: '/art-pieces/:theme',
        name: 'ArtGalery',
        component: () => import('../pages/ArtGalery')
    },
    {
        path: '/cuadros/:theme',
        name: 'ArtGalery',
        component: () => import('../pages/ArtGalery')
    },
    {
        path: '/art-pieces/:theme/*',
        name: 'ArtDetails',
        component: () => import('../pages/ArtDetails')
    },
    {
        path: '/cuadros/:theme/*',
        name: 'ArtDetails',
        component: () => import('../pages/ArtDetails')
    },
    {
        path: '/about',
        name: 'About',
        component: () => import('../pages/About')
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () => import('../pages/Contact')
    },
    {
        path: '/blog',
        name: 'Blog',
        component: () => import('../pages/Blog')
    },
    {
        path: '/portfolio',
        name: 'Portfolio',
        component: () => import('../pages/Portfolio')
    },
    {
        path: '/cart',
        name: 'ShoppingCart',
        component: () => import('../pages/ShoppingCart')
    },
    {
        path: '/checkout',
        name: 'Checkout',
        component: () => import('../pages/Checkout')
    },
    {
        path: '/order-status',
        name: 'OrderStatus',
        component: () => import('../pages/OrderStatus')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../pages/Login')
    },
    {
        path: '/qr',
        name: 'QR',
        component: () => import('../pages/QR')
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: () => import('../pages/ResetPassword')
    },
    {
        path: "*",
        component: () => import('../pages/Home')
    },
    {
        path: '/simulator/',
        name: 'Simulator',
        component: () => import('../pages/Simulator')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
	scrollBehavior() {
        return {x: 0, y: 0}
    }
})
export default router