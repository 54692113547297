import camelCase from 'lodash/camelCase'
import Vuex from "vuex";
import Vue from "vue";
import VuexPersist from 'vuex-persist';

const modulosStore = require.context("./modules", false);
const modules = {};

modulosStore.keys().forEach(fileName => {
	const moduleName = camelCase(fileName.split("/").pop().replace(/\.\w+$/, ''))
	modules[moduleName] = {
		...modulosStore(fileName).default
	}
})

// Load Vuex
Vue.use(Vuex);

const vuexSessionStorage = new VuexPersist({
	key:'vuex',
	storage: window.sessionStorage,
	reducer: state =>({
		shoppingCart:{
			shoppingCart: state.shoppingCart.shoppingCart,
			app_token: state.shoppingCart.app_token,
			buyAsGuest: state.shoppingCart.buyAsGuest,
			order: state.shoppingCart.order,
			artPieces: state.shoppingCart.artPieces,
			images: state.shoppingCart.images,
			visitedForm: state.shoppingCart.visitedForm,
		}
	})
})

// Create store
export default new Vuex.Store({
	plugins: [vuexSessionStorage.plugin],
	modules
});