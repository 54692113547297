<template>
    <v-navigation-drawer
        app
        expand-on-hover
        right
        width=180
    >
            <v-list>
                <v-list-item link @click="$router.push('/cart')">
                    <v-list-item-content>
                        <v-list-item-title class="title" >
                            {{cartLabel[languageId]}}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

        <v-row v-if="artPiecesOk">
            <v-col>
                <v-card 
                    v-for="(item, index) in shoppingCart"
                    :key="index"
                    @click="$router.push('/cart')"
                    style="padding:5px; margin:10px 5px;"
                >
                    <v-img 
                        :src="require(`../../assets/${getImage(item.title)}`)" 
                        :lazy-src="require(`../../assets/Mini-Pictures/${getImage(item.title).split('.')[0]}.jpg`)"
                        max-height="200" 
                        contain 
                    >
                        <template v-slot:placeholder>
                            <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                            >
                                <v-progress-circular
                                    indeterminate
                                    color="grey lighten-5"
                                ></v-progress-circular>
                            </v-row>
                        </template>
                    </v-img>
                </v-card>
            </v-col>
        </v-row>
    </v-navigation-drawer>
</template>


<script>
import { mapState, mapActions } from "vuex";
    export default {
        data () {
            return {
                items: [
                { title: 'Shopping', icon: 'mdi-chart-bar',     url: '/admin-dashboard' },
                { title: 'Reportes',  icon: 'mdi-clipboard',     url: '/admin-reportes'  },
                { title: 'Usuarios',  icon: 'mdi-account-group', url: '/admin-usuarios'  },
                ],
            }
        },
        computed: {
            ...mapState("shoppingCart", ["shoppingCart", "artPieces", "images", "artPiecesOk", "cartLabel", "languageId"]),
        },
        methods:{
            ...mapActions("shoppingCart", ["removeFromCart", ]),
            openPage(url){
                this.$router.push(url);
            },
			getImage(title){
				var artPiece = this.artPieces.find(x=>x.title.includes(title))
				var images = this.images.find(x=>x.idArtPiece == artPiece.id)
				return images.base64
			},
        }
    }
</script>