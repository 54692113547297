const state = {
};

const getters = {};

const actions = {
    setMutation({commit},{property:prop, with:data}){
        commit('mutate', {property:prop, with:data})
    },
};

const mutations = {
    mutate(state, payload) {
        state[payload.property] = payload.with;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};