import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import store from "./store";
import Vuex from 'vuex'
import GSignInButton from 'vue-google-signin-button'
import FBSignInButton from 'vue-facebook-signin-button'
import infiniteScroll from 'vue-infinite-scroll'
import VueMeta from 'vue-meta'

Vue.use(VueMeta)

Vue.use(GSignInButton)
Vue.use(FBSignInButton)

Vue.use(Vuex);

Vue.use(infiniteScroll)

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app')
