<template>
	<v-app :style="`max-width:${widthSize}px; margin:auto;`">
		<v-app-bar :class="displaySizeClass" app color="#404040" v-if="isSmallScreen == 'Mobile'" style="z-index:100;" >
			<v-menu >
				<template v-slot:activator="{ on, attrs }">
					<v-btn 
						v-bind="attrs"
						v-on="on"
						color="transparent" 
						elevation=0
						small 
					>
						<v-icon color="white">
							mdi-menu
						</v-icon>
					</v-btn>
				</template>
				<v-list elevation=5>
					<v-list-item>
							<a @click="$router.push('/')" class="nav-button" :class="{'text-yellow':this.$route.path=='/'}">
								{{homeButtonLabel[languageId]}}
							</a>
					</v-list-item>
					<v-list-item>
							<a @click="$router.push(`/${baseArtPiecesUrl[languageId]}`)" class="nav-button" :class="{'text-yellow':this.$route.path.includes(`/${baseArtPiecesUrl[languageId]}`)}">
								{{artPiecesButtonLabel[languageId]}}
							</a>	
					</v-list-item>
					<!-- <v-list-item>
							<a @click="$router.push('/about')" class="nav-button" :class="{'text-yellow':this.$route.path=='/about'}">
								{{aboutButtonLabel[languageId]}}
							</a>
					</v-list-item> -->
					<v-list-item>
							<a @click="$router.push('/contact')" class="nav-button" :class="{'text-yellow':this.$route.path=='/contact'}">
								{{contactButtonLabel[languageId]}}
							</a>	
					</v-list-item>
					<v-list-item>
							<a @click="$router.push('/blog')" class="nav-button" :class="{'text-yellow':this.$route.path=='/blog'}">
								{{blogButtonLabel[languageId]}}
							</a>	
					</v-list-item>
					<v-list-item>
							<a @click="$router.push('/portfolio')" class="nav-button" :class="{'text-yellow':this.$route.path=='/portfolio'}">
									{{portfolioButtonLabel[languageId]}}
							</a>
					</v-list-item>
					<v-list-item v-if="app_token != null&& !buyAsGuest">
							<a @click="$router.push('/order-status')" class="nav-button" :class="{'text-yellow':this.$route.path=='/order-status'}">
								{{myOrdersButtonLabel[languageId]}}
							</a>	
					</v-list-item>
					<v-list-item v-if="app_token == null">
							<a @click="$router.push('/login')" class="nav-button" :class="{'text-yellow':this.$route.path=='/login'}">
								{{loginLabel[languageId]}}
							</a>	
					</v-list-item>
					<v-list-item v-if="app_token != null">
							<a @click="signOut" class="nav-button">
								{{logoutLabel[languageId]}}
							</a>	
					</v-list-item>
				</v-list>
			</v-menu>
			<v-spacer></v-spacer>
			<div class="d-flex align-center" v-scroll="handleScroll" style="margin:0px;">
				<img @click="$router.push('/')" :alt="languageId==0?'Logo imagotipo corto de Karlus Morales':'Karlus Morales imagotype short logo'" src="./assets/KarlusMorales_Imagotype_Short_Dark.svg" class="navBarLogo" />
			</div>
			<v-spacer></v-spacer>
			<v-menu offset-y>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						class="mt-1"
						color="transparent"
						v-bind="attrs"
						v-on="on"
						depressed
						style="color:white;"
					>
						{{languages.find(x=>x.id == languageId).name}}
						<v-icon small color="white" >
							mdi-menu-down
						</v-icon>
					</v-btn>
				</template>
				<v-list dense>
					<v-subheader>{{languageLabel[languageId]}}</v-subheader>
					<v-list-item-group
						v-model="languageId"
						color="primary"
					>
						<v-list-item
							v-for="(item, index) in languages"
							:key="index"
							@click="setMutation({with:item.id,property:'languageId'})"
						>
							<v-list-item-content>
								<v-list-item-title v-text="item.name"></v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list-item-group>
				</v-list>
			</v-menu>
			<v-btn icon class="mr-4" color="white">
				<v-icon color="white" @click="$router.push('/cart')" >
					mdi-cart
				</v-icon>
			</v-btn>

		</v-app-bar>

		<v-app-bar :class="displaySizeClass" color="transparent" app absolute prominent elevation="0" style="justify-content:center;"  v-if="isSmallScreen == 'Desktop'">
			<v-row align="center" justify="space-around" no-gutters style="height:100%">
				<v-col cols=2 class="" >
					<img @click="$router.push('/')" :alt="languageId==0?'Logo imagotipo de Karlus Morales':'Karlus Morales imagotype logo'" src="./assets/KarlusMorales_Imagotype_Dark_120.svg" class="navBarLogoFirst shadow img-shadow" style="padding:0px 40px;" />
				</v-col>
				<v-col cols=8>
					<v-row
						align="center"
						justify="center"
						no-gutters
						style="height:100%"
					>
						<button class="mr-10 buttonWidth poppinsboldFont buttonHover shadow" :class="this.$route.path=='/'?'box':''" style="color:white; font-size:0.875rem; text-transform: uppercase;" @click="$router.push('/')">
							{{homeButtonLabel[languageId]}}
						</button>

						<v-menu v-model="artworkButton" bottom offset-y :close-on-content-click="false" open-on-hover>
							<template v-slot:activator="{on,attrs}">
								<button v-bind="attrs" v-on="on"  class="mr-10 buttonWidth poppinsboldFont buttonHover shadow" style="color:white; font-size:0.875rem; text-transform: uppercase;">
									{{artPiecesButtonLabel[languageId]}}
								</button> 
							</template>
							<v-card accordion>
								<v-card-text>
										<v-list dense >
											<v-list-item v-for="(data,index) in themesNames" :key="index" link @click="filterData(data.id,'themes')">
												<v-list-item-content>
													<v-list-item-title class="text-left v-expansion-panel-content poppinsRegularFont"  >
														<a :class="{active:(data.id==themesFilter)}">{{data.name.split("|")[languageId]}}</a>
													</v-list-item-title>
												</v-list-item-content>
											</v-list-item>
										</v-list>
								</v-card-text>
							</v-card>
						</v-menu>

						<v-menu v-model="shapesButton" bottom offset-y :close-on-content-click="false" open-on-hover>
							<template v-slot:activator="{on,attrs}">
								<button v-bind="attrs" v-on="on"  class="mr-10 buttonWidth poppinsboldFont buttonHover shadow" style="color:white; font-size:0.875rem; text-transform: uppercase;">
									{{shapesLabel[languageId]}}
								</button> 
							</template>
							<v-card accordion>
								<v-card-text>
										<v-list dense >
											<v-list-item v-for="(data,index) in shapesNames" :key="index" link @click="filterData(data.id,'shapes')">
												<v-list-item-content>
													<v-list-item-title class="text-left v-expansion-panel-content poppinsRegularFont"  >
														<a :class="{active:(data.id==shapesFilter)}">{{data.name.split("|")[languageId]}}</a>
													</v-list-item-title>
												</v-list-item-content>
											</v-list-item>
										</v-list>
								</v-card-text>
							</v-card>
						</v-menu>

						<v-menu v-model="colorsButton" bottom offset-y :close-on-content-click="false" open-on-hover>
							<template v-slot:activator="{on,attrs}">
								<button v-bind="attrs" v-on="on"  class="mr-10 buttonWidth poppinsboldFont buttonHover shadow" style="color:white; font-size:0.875rem; text-transform: uppercase;">
									{{colorsLabel[languageId]}}
								</button> 
							</template>
							<v-card accordion>
								<v-card-text>
										<v-list dense >
											<v-list-item v-for="(data,index) in colorsNames" :key="index" link @click="filterData(data.id,'colors')">
												<v-list-item-content>
													<v-list-item-title class="text-left v-expansion-panel-content poppinsRegularFont"  >
														<a :class="{active:(data.id==colorsFilter)}">{{data.name.split("|")[languageId]}}</a>
													</v-list-item-title>
												</v-list-item-content>
											</v-list-item>
										</v-list>
								</v-card-text>
							</v-card>
						</v-menu>

						<button class="mr-10 buttonWidth poppinsboldFont buttonHover shadow" :class="this.$route.path=='/portfolio'?'box':''" style="color:white; font-size:0.875rem; text-transform: uppercase;" @click="$router.push('/portfolio')">
								{{portfolioButtonLabel[languageId]}}
						</button>

						<button class="mr-10 buttonWidth poppinsboldFont buttonHover shadow" :class="this.$route.path=='/blog'?'box':''" style="color:white; font-size:0.875rem; text-transform: uppercase;" @click="$router.push('/blog')">
							{{blogButtonLabel[languageId]}}
						</button>

						<button class="mr-10 buttonWidth poppinsboldFont buttonHover shadow" :class="this.$route.path=='/contact'?'box':''" style="color:white; font-size:0.875rem; text-transform: uppercase;" @click="$router.push('/contact')">
							{{contactButtonLabel[languageId]}}
						</button>

						<button v-if="app_token != null && !buyAsGuest" class="mr-10 buttonWidth poppinsboldFont buttonHover shadow" :class="this.$route.path=='/order-status'?'box':''" style="color:white; font-size:0.875rem; text-transform: uppercase;" @click="$router.push('/order-status')">
							{{myOrdersButtonLabel[languageId]}}
						</button>
						<!-- <button class="mr-10 buttonWidth poppinsboldFont buttonHover" :class="this.$route.path=='/'?'box':''" style="color:white; font-size:0.875rem; text-transform: uppercase;" @click="$router.push('/')">
							{{homeButtonLabel[languageId]}}
						</button>
						<button class="mr-10 buttonWidth poppinsboldFont buttonHover" :class="this.$route.path.includes(`/${baseArtPiecesUrl[languageId]}`)?'box':''" style="color:white; font-size:0.875rem; text-transform: uppercase;" @click="$router.push(`/${baseArtPiecesUrl[languageId]}`)">
							{{artPiecesButtonLabel[languageId]}}
						</button>
						<button class="mr-10 buttonWidth poppinsboldFont buttonHover" :class="this.$route.path=='/about'?'box':''" style="color:white; font-size:0.875rem; text-transform: uppercase;" @click="$router.push('/about')">
							{{aboutButtonLabel[languageId]}}
						</button>
						<button class="mr-10 buttonWidth poppinsboldFont buttonHover" :class="this.$route.path=='/contact'?'box':''" style="color:white; font-size:0.875rem; text-transform: uppercase;" @click="$router.push('/contact')">
							{{contactButtonLabel[languageId]}}
						</button>
						<button v-if="app_token != null && !buyAsGuest" class="mr-10 buttonWidth poppinsboldFont buttonHover" :class="this.$route.path=='/order-status'?'box':''" style="color:white; font-size:0.875rem; text-transform: uppercase;" @click="$router.push('/order-status')">
							{{myOrdersButtonLabel[languageId]}}
						</button> -->
					</v-row>
				</v-col>
				<v-col cols=2>
					<v-row
						align="center"
						justify="end"
						no-gutters
						style="height:100%"
						class="mr-12"
					>
						<v-btn icon v-if="app_token == null" class="mr-1 buttonHover button-shadow" @click="$router.push('/login')" :outlined="this.$route.path=='/login'">
							<v-avatar size="27">
								<v-icon color="white">
									mdi-account-outline
								</v-icon>
							</v-avatar>
						</v-btn>
						<v-btn icon v-if="app_token != null" class="mr-1 buttonHover button-shadow" @click="signOut">
							<v-avatar size="27">
								<v-icon color="white">
									mdi-account-off-outline
								</v-icon>
							</v-avatar>
						</v-btn>
						<v-btn icon @click="$router.push('/cart')"  :outlined="this.$route.path=='/cart'" class="buttonHover button-shadow">
							<v-avatar size="27">
								<v-icon color="white" >
									mdi-cart
								</v-icon>
							</v-avatar>
						</v-btn>
						<v-menu offset-y>
							<template v-slot:activator="{ on, attrs }">
								<!-- <v-btn
									color="transparent"
									class="mt-1 poppinsboldFont buttonHover shadow"
									v-bind="attrs"
									v-on="on"
									depressed
									style="color:white;"
								> -->
								<button v-bind="attrs" v-on="on"  class="mr-1 poppinsboldFont buttonHover shadow" style="color:white; font-size:0.875rem; text-transform: uppercase; width:65px; height:41px;">
									<span>
										{{languages.find(x=>x.id == languageId).name}}
										<v-icon small color="white">
											mdi-menu-down
										</v-icon>
									</span>
								</button>
								<!-- </v-btn> -->
							</template>
							<v-list dense>
								<v-subheader>{{languageLabel[languageId]}}</v-subheader>
								<v-list-item-group
									v-model="languageId"
									color="primary"
								>
									<v-list-item
										v-for="(item, index) in languages"
										:key="index"
										@click="setMutation({with:item.id,property:'languageId'})"
									>
										<v-list-item-content>
											<v-list-item-title v-text="item.name"></v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</v-list-item-group>
							</v-list>
						</v-menu>
					</v-row>
				</v-col>
			</v-row>
		</v-app-bar>

		<v-app-bar :class="displaySizeClass" color="transparent" app absolute prominent elevation="0" style="justify-content:center;"  v-if="isSmallScreen == 'Tablet'">
			<v-row align="center" justify="space-around" no-gutters style="height:100%">
				<v-col cols=2 class="">
					<img @click="$router.push('/')" :alt="languageId==0?'Logo imagotipo de Karlus Morales':'Karlus Morales imagotype logo'" src="./assets/KarlusMorales_Imagotype_Dark_120.svg" class="navBarLogoFirst shadow img-shadow"  />
				</v-col>
				<v-col cols=8>
					<v-row
						align="center"
						justify="center"
						no-gutters
						style="height:100%"
					>
						<!-- <button class="mr-5 buttonWidth-tablet poppinsboldFont buttonHover" :class="this.$route.path=='/'?'box':''" style="color:white; font-size:0.875rem; text-transform: uppercase;" @click="$router.push('/')">
							{{homeButtonLabel[languageId]}}
						</button>
						<button class="mr-5 buttonWidth-tablet poppinsboldFont buttonHover" :class="this.$route.path.includes(`/${baseArtPiecesUrl[languageId]}`)?'box':''" style="color:white; font-size:0.875rem; text-transform: uppercase;" @click="$router.push(`/${baseArtPiecesUrl[languageId]}`)">
							{{artPiecesButtonLabel[languageId]}}
						</button> -->
						<!-- <button class="mr-5 buttonWidth-tablet poppinsboldFont buttonHover" :class="this.$route.path=='/about'?'box':''" style="color:white; font-size:0.875rem; text-transform: uppercase;" @click="$router.push('/about')">
							{{aboutButtonLabel[languageId]}}
						</button> -->
						<!-- <button class="mr-5 buttonWidth-tablet poppinsboldFont buttonHover" :class="this.$route.path=='/contact'?'box':''" style="color:white; font-size:0.875rem; text-transform: uppercase;" @click="$router.push('/contact')">
							{{contactButtonLabel[languageId]}}
						</button>
						<button v-if="app_token != null && !buyAsGuest" class="mr-5 buttonWidth-tablet poppinsboldFont buttonHover" :class="this.$route.path=='/order-status'?'box':''" style="color:white; font-size:0.875rem; text-transform: uppercase;" @click="$router.push('/order-status')">
							{{myOrdersButtonLabel[languageId]}}
						</button> -->
						
						<button class="mr-10 buttonWidth poppinsboldFont buttonHover shadow" :class="this.$route.path=='/'?'box':''" style="color:white; font-size:0.875rem; text-transform: uppercase;" @click="$router.push('/')">
							{{homeButtonLabel[languageId]}}
						</button>

						<v-menu v-model="artworkButton" bottom offset-y :close-on-content-click="false" open-on-hover>
							<template v-slot:activator="{on,attrs}">
								<button v-bind="attrs" v-on="on"  class="mr-10 buttonWidth poppinsboldFont buttonHover shadow" style="color:white; font-size:0.875rem; text-transform: uppercase;">
									{{artPiecesButtonLabel[languageId]}}
								</button> 
							</template>
							<v-card accordion>
								<v-card-text>
										<v-list dense >
											<v-list-item v-for="(data,index) in themesNames" :key="index" link @click="filterData(data.id,'themes')">
												<v-list-item-content>
													<v-list-item-title class="text-left v-expansion-panel-content poppinsRegularFont"  >
														<a :class="{active:(data.id==themesFilter)}">{{data.name.split("|")[languageId]}}</a>
													</v-list-item-title>
												</v-list-item-content>
											</v-list-item>
										</v-list>
								</v-card-text>
							</v-card>
						</v-menu>

						<!-- <v-menu v-model="shapesButton" bottom offset-y :close-on-content-click="false" open-on-hover>
							<template v-slot:activator="{on,attrs}">
								<button v-bind="attrs" v-on="on"  class="mr-10 buttonWidth poppinsboldFont buttonHover" style="color:white; font-size:0.875rem; text-transform: uppercase;">
									{{shapesLabel[languageId]}}
								</button> 
							</template>
							<v-card accordion>
								<v-card-text>
										<v-list dense >
											<v-list-item v-for="(data,index) in shapesNames" :key="index" link @click="filterData(data.id,'shapes')">
												<v-list-item-content>
													<v-list-item-title class="text-left v-expansion-panel-content poppinsRegularFont"  >
														<a :class="{active:(data.id==shapesFilter)}">{{data.name.split("|")[languageId]}}</a>
													</v-list-item-title>
												</v-list-item-content>
											</v-list-item>
										</v-list>
								</v-card-text>
							</v-card>
						</v-menu>

						<v-menu v-model="colorsButton" bottom offset-y :close-on-content-click="false" open-on-hover>
							<template v-slot:activator="{on,attrs}">
								<button v-bind="attrs" v-on="on"  class="mr-10 buttonWidth poppinsboldFont buttonHover" style="color:white; font-size:0.875rem; text-transform: uppercase;">
									{{colorsLabel[languageId]}}
								</button> 
							</template>
							<v-card accordion>
								<v-card-text>
										<v-list dense >
											<v-list-item v-for="(data,index) in colorsNames" :key="index" link @click="filterData(data.id,'colors')">
												<v-list-item-content>
													<v-list-item-title class="text-left v-expansion-panel-content poppinsRegularFont"  >
														<a :class="{active:(data.id==colorsFilter)}">{{data.name.split("|")[languageId]}}</a>
													</v-list-item-title>
												</v-list-item-content>
											</v-list-item>
										</v-list>
								</v-card-text>
							</v-card>
						</v-menu> -->

						<button class="mr-10 buttonWidth poppinsboldFont buttonHover shadow" :class="this.$route.path=='/portfolio'?'box':''" style="color:white; font-size:0.875rem; text-transform: uppercase;" @click="$router.push('/portfolio')">
								{{portfolioButtonLabel[languageId]}}
						</button>

						<button class="mr-10 buttonWidth poppinsboldFont buttonHover shadow" :class="this.$route.path=='/blog'?'box':''" style="color:white; font-size:0.875rem; text-transform: uppercase;" @click="$router.push('/blog')">
							{{blogButtonLabel[languageId]}}
						</button>

						<!-- <button class="mr-10 buttonWidth poppinsboldFont buttonHover shadow" :class="this.$route.path=='/contact'?'box':''" style="color:white; font-size:0.875rem; text-transform: uppercase;" @click="$router.push('/contact')">
							{{contactButtonLabel[languageId]}}
						</button> -->

						<button v-if="app_token != null && !buyAsGuest" class="mr-10 buttonWidth poppinsboldFont buttonHover shadow" :class="this.$route.path=='/order-status'?'box':''" style="color:white; font-size:0.875rem; text-transform: uppercase;" @click="$router.push('/order-status')">
							{{myOrdersButtonLabel[languageId]}}
						</button>
					</v-row>
				</v-col>
				<v-col cols=2>
					<v-row
						align="center"
						justify="end"
						no-gutters
						style="height:100%; min-width:170px;"
					>
						<v-btn icon v-if="app_token == null"  @click="$router.push('/login')" :outlined="this.$route.path=='/login'" class="buttonHover button-shadow">
							<v-avatar size="27" >
								<v-icon color="white">
									mdi-account-outline
								</v-icon>
							</v-avatar>
						</v-btn>
						<v-btn icon v-if="app_token != null" @click="signOut" class="buttonHover button-shadow">
							<v-avatar size="27">
								<v-icon color="white">
									mdi-account-off-outline
								</v-icon>
							</v-avatar>
						</v-btn>
						<v-btn icon @click="$router.push('/cart')"  :outlined="this.$route.path=='/cart'" class="buttonHover button-shadow">
							<v-avatar size="27">
								<v-icon color="white" >
									mdi-cart
								</v-icon>
							</v-avatar>
						</v-btn>
						<v-menu offset-y>
							<template v-slot:activator="{ on, attrs }">
								<!-- <v-btn
									color="transparent"
									class="poppinsboldFont buttonHover shadow"
									v-bind="attrs"
									v-on="on"
									depressed
									style="color:white;"
								> -->
								<button v-bind="attrs" v-on="on"  class="mr-1 poppinsboldFont buttonHover shadow" style="color:white; font-size:0.875rem; text-transform: uppercase; width:65px; height:41px;">
									<span>
										{{languages.find(x=>x.id == languageId).name}}
										<v-icon small color="white" >
											mdi-menu-down
										</v-icon>
									</span>
								</button>
								<!-- </v-btn> -->
							</template>
							<v-list dense>
								<v-subheader>{{languageLabel[languageId]}}</v-subheader>
								<v-list-item-group
									v-model="languageId"
									color="primary"
								>
									<v-list-item
										v-for="(item, index) in languages"
										:key="index"
										@click="setMutation({with:item.id,property:'languageId'})"
									>
										<v-list-item-content>
											<v-list-item-title v-text="item.name"></v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</v-list-item-group>
							</v-list>
						</v-menu>
					</v-row>
				</v-col>
			</v-row>
		</v-app-bar>

		<ShoppingCartDrawer v-if="showDrawer"></ShoppingCartDrawer>
		<v-main >
			<router-view :key="$route.fullPath" />
			<div style="margin-top:48px;"></div>
		</v-main>
	</v-app>
</template>

<script>
import ShoppingCartDrawer from './pages/components/ShoppingCartDrawer';
// import filterListData from './pages/components/FilterListData'
import { mapState, mapActions } from "vuex";
import axios from "axios";
const baseURL = process.env.VUE_APP_BASE_URL_API;

	export default {
		name: 'App',
		components: { ShoppingCartDrawer, /*filterListData*/ },
		data: () => ({
            languageLabel:["Idioma","Language"],

			isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			),
			scrolled: false,
			
			direction: 'top',
			fab: false,
			fling: false,
			hover: false,
			tabs: null,
			top: false,
			right: true,
			bottom: true,
			left: false,
			transition: 'slide-y-reverse-transition',
			homeButton: false,
			artworkButton: false,
			shapesButton: false,
			colorsButton: false,
		}),
		created(){
			this.getCollections();
			this.getArtPiecesProperties();
			this.getArtPieces();
		},
		mounted(){
			if (window.localStorage.SetLanguageId) {
				this.setMutation({with:window.localStorage.SetLanguageId,property:'languageId'})
			} else {
				var language = navigator.language;
				if(language.split("-")[0] == "es"){
					this.setMutation({with:0,property:'languageId'})
				}
				else{
					this.setMutation({with:1,property:'languageId'})
				}
			}
		},
		methods:{
            ...mapActions("shoppingCart", ["removeFromCart", "logout", "setMutation", "filter"]),
			handleScroll(){
				if(window.scrollY > 1){
					this.scrolled = true;
				}
				else{
					this.scrolled = false;
				}
			},
			signOut(){
                this.setMutation({with:false,property:'checkoutClick'});
				this.logout();
				this.$router.push('/login');
			},

			getArtPiecesProperties(){
                axios.get(`${baseURL}ArtPieces/properties`)
                    .then(res => {
                        if(res.status == 200){
							this.setMutation({with:res.data.sizes,property:'size'})
							this.setMutation({with:res.data.printedMediums,property:'printedMedium'})
							this.setMutation({with:res.data.frames,property:'frame'})
							this.setMutation({with:res.data.liners,property:'liner'})
							this.setMutation({with:res.data.shippingValues,property:'shippingValues'})

							var empty = 
							{
								id:"",
								name:"Todo|All",
								description:"descripción|description",
								idImage:null,
							}

							this.setMutation({with:res.data.categories,property:'categories'})
							var categories = res.data.categories
							this.setMutation({with:categories,property:'categoriesNames'})

							this.setMutation({with:res.data.colors,property:'colors'})
							var colors = res.data.colors
							colors.unshift(empty);
							this.setMutation({with:colors,property:'colorsNames'})

							this.setMutation({with:res.data.styles,property:'styles'})
							var styles = res.data.styles
							styles.unshift(empty);
							this.setMutation({with:styles,property:'stylesNames'})

							this.setMutation({with:res.data.rooms,property:'rooms'})
							var rooms = res.data.rooms
							rooms.unshift(empty);
							this.setMutation({with:rooms,property:'roomsNames'})

							this.setMutation({with:res.data.themes,property:'themes'})
							var themes = res.data.themes
							themes.unshift(empty);
							this.setMutation({with:themes,property:'themesNames'})

							this.setMutation({with:res.data.shapes,property:'shapes'})
							var shapes = res.data.shapes
							shapes.unshift(empty);
							this.setMutation({with:shapes,property:'shapesNames'})

							this.setMutation({with:true,property:'propertiesOk'})
                        }
                    })
                    .catch(error => { 
                        console.log('sendRequest',error);
                    });
			},
			getArtPieces(){
                axios.get(`${baseURL}ArtPieces`)
                    .then(res => {
                        if(res.status == 200){
							this.setMutation({with:res.data.artPieces,property:'artPieces'})
							this.setMutation({with:res.data.images,property:'images'})
							this.setMutation({with:res.data.soldUnits,property:'soldUnits'})
							this.setMutation({with:true,property:'artPiecesOk'})
                        }
                    })
                    .catch(error => { 
                        console.log('sendRequest',error);
                    });
			},
			getCollections(){
                axios.get(`${baseURL}Collections/all`)
                    .then(res => {
                        if(res.status == 200){
							var emptyCollection = 
							{
								id:"",
								name:"Todo|All",
								description:"descripción|description",
								idImage:null,
							}
							var collections = res.data.collections
							collections.unshift(emptyCollection);
							this.setMutation({with:collections,property:'collectionNames'})
							this.setMutation({with:true,property:'collectionNamesOk'})
                        }
                    })
                    .catch(error => { 
                        console.log('sendRequest',error);
                    });
			},
			
            filterData(id,filterName){
                this.filter({property:`${filterName}Filter`,with:id});
				if(filterName == 'themes'){
					let themeUrl = this.themesNames.find(x=>x.id == id)?.name?.split("|")[this.languageId];
					this.redirectUrl(`${this.baseArtPiecesUrl[this.languageId]}/${themeUrl}`);
				}
				else{
					if(!this.baseArtPiecesUrl.some(r=>this.$route.path.includes(r))){
						this.redirectUrl(`${this.baseArtPiecesUrl[this.languageId]}`)
					}
				}
            },
            cleanFilter(filterName){
                this.filter({property:`${filterName}Filter`,with:0});
            },
			redirectUrl(path){
				this.$router.push(`/${path}`)
			},
		},
		computed:{
            ...mapState("shoppingCart", 
				[
					"shoppingCart", "artPieces", "size", "sizePortrait", "printedMedium", "frame", "liner", "app_token", "buyAsGuest", "languageId", "languages",
					"homeButtonLabel", "artPiecesButtonLabel", "aboutButtonLabel", "contactButtonLabel", "myOrdersButtonLabel", "loginLabel", "logoutLabel",
					"baseArtPiecesUrl", "blogButtonLabel",
					"categoriesLabel","colorsLabel","stylesLabel","roomsLabel","themesLabel","shapesLabel",
					"categoriesNames","colorsNames","stylesNames","roomsNames","themesNames","shapesNames",
					"categoriesFilter","colorsFilter","stylesFilter","roomsFilter","themesFilter","shapesFilter", "portfolioButtonLabel",
				]),
			showDrawer(){
				return (this.shoppingCart.length>0 && this.$route.path !='/checkout' && this.$route.path !='/cart')
			},
			isSmallScreen(){
				var screenType = "Desktop";
				if(this.displaySizeClass == 'mobile-size')
					screenType = "Mobile";
				if(this.displaySizeClass == 'tablet-size')
					screenType = "Tablet";
				return screenType
			},
			displaySizeClass(){
				var displayClass = "desktop-size";
				var windowWidth = this.widthSize;
				if(windowWidth <= 640)
					displayClass = "mobile-size";
				if(windowWidth >= 1024 && windowWidth <= 1440)
					displayClass = "tablet-size";
				return displayClass
			},
			widthSize(){
				var windowWidth = window.innerWidth;
				if(windowWidth < 320)
					windowWidth = 320
				if(windowWidth > 640 && windowWidth < 1024)
					windowWidth = 640
				if(windowWidth > 1440 && windowWidth < 1920)
					windowWidth = 1440
				if(windowWidth >= 1920)
					windowWidth = 1920
				return windowWidth
			},
		},

		watch: {
			top (val) {
				this.bottom = !val
			},
			right (val) {
				this.left = !val
			},
			bottom (val) {
				this.top = !val
			},
			left (val) {
				this.right = !val
			},
			languageId(newlanguageId) {
				window.localStorage.SetLanguageId = newlanguageId;
			},
		},
		// metaInfo(){
		// 	return{
		// 		htmlAttrs: {
		// 			lang: 'en',
		// 		}
		// },
	};
</script>

<style scoped>
	.navBarLogo{
		height:48px;
	}
	.navBarLogoFirst{
		height:120px;
	}
	.navBarLogoFirst:hover{
		cursor:pointer;
	}
	.text-white{
		color:white;
	}
	.nav-button{
		width:120px;
	}
	.nav-button:hover{
		text-decoration: underline;
	} 
	.buttonWidth{
		width: 125px;
		padding: 10px 0px;
	}
	.buttonWidth-tablet{
		width: 100px;
		padding: 10px 0px;
	}
    .active{
        font-weight:bold;
    }
	/* .img-shadow{
		background: radial-gradient(circle, rgba(0,0,0,0.2) 0%, transparent 90%); 
	} */
</style>

<style>

@font-face {
	font-family: "Myriad-Pro-Regular";
	src: local("Myriad-Pro-Regular"), url(./assets/fonts/Myriad/Myriad-Pro-Regular.ttf) format("truetype");
	font-display: swap;
}
.myriadProRegularFont{
	font-family: "Myriad-Pro-Regular";
}
@font-face {
	font-family: "Myriad-Pro-Bold";
	src: local("Myriad-Pro-Bold"), url(./assets/fonts/Myriad/Myriad-Pro-Bold.ttf) format("truetype");
	font-display: swap;
}
.myriadProBoldFont{
	font-family: "Myriad-Pro-Bold";
}
@font-face {
	font-family: "Myriad-Pro-Light";
	src: local("Myriad-Pro-Light"), url(./assets/fonts/Myriad/Myriad-Pro-Light.otf) format("opentype");
	font-display: swap;
}
.myriadProLightFont{
	font-family: "Myriad-Pro-Light";
}
@font-face {
	font-family: "Luscious-Lifestyles-Script";
	src: local("Luscious-Lifestyles-Script"), url(./assets/fonts/Luscious-Lifestyles/Luscious-Lifestyles-Script.ttf) format("truetype");
	font-display: swap;
}
.lusciousLifestylesScriptFont{
	font-family: "Luscious-Lifestyles-Script";
	font-weight: 100;
}
@font-face {
	font-family: "Poppins-Bold";
	src: local("Poppins-Bold"), url(./assets/fonts/Poppins/Poppins-Bold.ttf) format("truetype");
	font-display: swap;
}
.poppinsboldFont{
	font-family: "Poppins-Bold" !important;
}
@font-face {
	font-family: "Poppins-ExtraBold";
	src: local("Poppins-ExtraBold"), url(./assets/fonts/Poppins/Poppins-ExtraBold.ttf) format("truetype");
	font-display: swap;
}
.poppinsExtraBoldFont{
	font-family: "Poppins-ExtraBold";
}
@font-face {
	font-family: "Poppins-ExtraLight";
	src: local("Poppins-ExtraLight"), url(./assets/fonts/Poppins/Poppins-ExtraLight.ttf) format("truetype");
	font-display: swap;
}
.poppinsExtraLightFont{
	font-family: "Poppins-ExtraLight";
}
@font-face {
	font-family: "Poppins-Light";
	src: local("Poppins-Light"), url(./assets/fonts/Poppins/Poppins-Light.ttf) format("truetype");
	font-display: swap;
}
.poppinsLightFont{
	font-family: "Poppins-Light";
}
@font-face {
	font-family: "Poppins-Medium";
	src: local("Poppins-Medium"), url(./assets/fonts/Poppins/Poppins-Medium.ttf) format("truetype");
	font-display: swap;
}
.poppinsMediumFont{
	font-family: "Poppins-Medium";
}
@font-face {
	font-family: "Poppins-Regular";
	src: local("Poppins-Regular"), url(./assets/fonts/Poppins/Poppins-Regular.ttf) format("truetype");
	font-display: swap;
}
.poppinsRegularFont{
	font-family: "Poppins-Regular";
}
@font-face {
	font-family: "Poppins-SemiBold";
	src: local("Poppins-SemiBold"), url(./assets/fonts/Poppins/Poppins-SemiBold.ttf) format("truetype");
	font-display: swap;
}
.poppinsSemiboldFont{
	font-family: "Poppins-SemiBold";
}
@font-face {
	font-family: "Poppins-Black";
	src: local("Poppins-Black"), url(./assets/fonts/Poppins/Poppins-Black.ttf) format("truetype");
	font-display: swap;
}
.poppinsBlackFont{
	font-family: "Poppins-Black";
}


@font-face {
	font-family: "Proxima-Nova-Bold";
	src: local("Proxima-Nova-Bold"), url(./assets/fonts/Proxima-Nova-Font/Proxima-Nova-Bold.otf) format("opentype");
	font-display: swap;
	}
.proximaNovaBoldFont{
	font-family: "Proxima-Nova-Bold";
}

@font-face {
	font-family: "Didot";
	src: local("Didot"), url(./assets/fonts/Didot/Didot.ttf) format("truetype");
	font-display: swap;
	}
.didotFont{
	font-family: "Didot";
}

@font-face {
	font-family: "Didot-Italic";
	src: local("Didot-Italic"), url(./assets/fonts/Didot/Didot-Italic.ttf) format("truetype");
	font-display: swap;
	}
.didotItalicFont{
	font-family: "Didot-Italic";
}

.arialFont{
	font-family: "Roboto, sans-serif";
}

.background-darker{
	background-color: rgb(0,0,0,0.3);
}
.text-shadow{
	text-shadow: 1px 1px 2px black;
}

.desktop-size{
	min-width:1920px !important;
	max-width:1920px !important;
	margin:auto;
}
.tablet-size{
	min-width:1024px !important;
	max-width:1440px !important;
	margin:auto;
}
.mobile-size{
	min-width:320px !important;
	max-width:640px !important;
	margin:auto;
}

.text-yellow{
	color:#D79A2B !important;
}
h1{
	font-size: 30px
}
.box {
	padding: 10px;
	background-image: linear-gradient(white, white),
					linear-gradient(white, white),
					linear-gradient(white, white),
					linear-gradient(white, white),
					linear-gradient(transparent, transparent);
	background-repeat: no-repeat;
	background-size: 1px 50%, 25% 1px, 1px 50%, 25% 1px, calc(100% - 2px) calc(100% - 2px);
	background-position: right bottom, right bottom, left top, left top, 1px 1px;
}

.colorBeige{
	color: rgb(240,228,178) !important;
}
.colorYellow{
	color: rgb(213,135,43) !important;
}
.colorOrange{
	color: rgb(255,92,53) !important;
}
.colorBlue{
	color: rgb(3,92,103) !important;
}
.colorGreen{
	color: rgb(65,73,36) !important;
}
.colorBrown{
	color: rgb(72,55,41) !important;
}
.colorBlack{
	color: rgb(34,34,34) !important;
}
.colorWhite{
	color:white !important;
}
.colorGrey{
	color: #A8A8A8 !important;
}
.colorOldBrown{
	color: #BEA67C !important;
}
.colorDarkGrey{
	color: #848484 !important;
}
.colorDarkBrown{
	color: #483729 !important;
}

.backgroundTransparent{
	background-color:transparent !important;
}
.backgroundBeige{
	background-color: rgb(240,228,178) !important;
}
.backgroundYellow{
	background-color: rgb(213,135,43) !important;
}
.backgroundOrange{
	background-color: rgb(255,92,53) !important;
}
.backgroundBlue{
	background-color: rgb(3,92,103) !important;
}
.backgroundGreen{
	background-color: rgb(65,73,36) !important;
}
.backgroundBrown{
	background-color: rgb(72,55,41) !important;
}
.backgroundBlack{
	background-color: rgb(34,34,34) !important;
}
.backgroundWhite{
	background-color:white !important;
}

.backgroundGrey{
	background-color: #A8A8A8 !important;
}
.backgroundDarkGrey{
	background-color: #848484 !important;
}
.backgroundDarkBrown{
	background-color: #483729 !important;
}

.backgroundLightBeige{
	background-color: rgb(240,228,178,0.6) !important;
}
.backgroundLightYellow{
	background-color: rgb(213,135,43,0.6) !important;
}
.backgroundLightOrange{
	background-color: rgb(255,92,53,0.6) !important;
}
.backgroundLightBlue{
	background-color: rgb(3,92,103,0.6) !important;
}
.backgroundLightGreen{
	background-color: rgb(65,73,36,0.6) !important;
}
.backgroundLightBrown{
	background-color: rgb(72,55,41,0.6) !important;
}
.backgroundLightBlack{
	background-color: rgb(34,34,34,0.6) !important;
}
.backgroundLightWhite{
	background-color:rgb(255,255,255,0.6) !important;
}
.backgroundLightGrey{
	background-color:rgb(168,168,168,0.6) !important;
}


.borderBeige-light{
	border: 1px solid rgb(240,228,178) !important;
}
.borderYellow-light{
	border: 1px solid rgb(213,135,43) !important;
}
.borderOrange-light{
	border: 1px solid rgb(255,92,53) !important;
}
.borderBlue-light{
	border: 1px solid rgb(3,92,103) !important;
}
.borderGreen-light{
	border: 1px solid rgb(65,73,36) !important;
}
.borderNormalBrown-light{
	border: 1px solid rgb(72,55,41) !important;
}
.borderBlack-light{
	border: 1px solid rgb(34,34,34) !important;
}
.borderWhite-light{
	border:1px solid white !important;
}

.borderBeige{
	border: 5px solid rgb(240,228,178) !important;
}
.borderYellow{
	border: 5px solid rgb(213,135,43) !important;
}
.borderOrange{
	border: 5px solid rgb(255,92,53) !important;
}
.borderBlue{
	border: 5px solid rgb(3,92,103) !important;
}
.borderGreen{
	border: 5px solid rgb(65,73,36) !important;
}
.borderNormalBrown{
	border: 5px solid rgb(72,55,41) !important;
}
.borderBlack{
	border: 5px solid rgb(34,34,34) !important;
}
.borderWhite{
	border: 5px solid white !important;
}

.break-on-whitespace{
	word-break: normal;
	white-space: break-spaces !important; 
}
.uppercase{
	text-transform: uppercase;
}
.underline{
	text-decoration:underline;
}
.noSelect{
	user-select:none;
}
.buttonHover:hover{
	cursor: pointer;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	background-color: rgb(255,92,53);
	text-shadow: none !important;
}
.flex-center{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.hover-pointer{
	cursor: pointer;
}
.no-padding{
	padding: 0px !important;
}
.no-margin{
	margin: 0px !important;
}

.shadow{
	/* text-shadow: 0px 0px 20px dimgray, 0px 0px 60px black; */
	/* text-shadow: 0 0 2vw rgba(65, 73, 36, 1), 0.05vw 0.05vw 2vw rgba(65, 73, 36, 1), 0.08vw 0.08vw 1vw rgba(65, 73, 36, 0.9), 0.11vw 0.11vw 1vw rgba(65, 73, 36, 0.8), 0.14vw 0.14vw 0 rgba(65, 73, 36, 0.7), 0.17vw 0.17vw 0 rgba(65, 73, 36, 0.6), 0.2vw 0.2vw 0 rgba(65, 73, 36, 0.5), 0.25vw 0.25vw 0 rgba(65, 73, 36, 0.4), 0.3vw 0.3vw 0 rgba(65, 73, 36, 0.3), 0.35vw 0.35vw 0vw rgba(65, 73, 36, 0.2); */
}
.shadow-category{
	text-shadow: 0px 0px 20px dimgray, 0px 0px 60px dimgray;
	/* text-shadow: 0 0 2vw rgba(65, 73, 36, 1), 0.05vw 0.05vw 2vw rgba(65, 73, 36, 1), 0.08vw 0.08vw 1vw rgba(65, 73, 36, 0.9), 0.11vw 0.11vw 1vw rgba(65, 73, 36, 0.8), 0.14vw 0.14vw 0 rgba(65, 73, 36, 0.7), 0.17vw 0.17vw 0 rgba(65, 73, 36, 0.6), 0.2vw 0.2vw 0 rgba(65, 73, 36, 0.5), 0.25vw 0.25vw 0 rgba(65, 73, 36, 0.4), 0.3vw 0.3vw 0 rgba(65, 73, 36, 0.3), 0.35vw 0.35vw 0vw rgba(65, 73, 36, 0.2); */
}
.button-shadow{
	/* text-shadow:  0 0 0.5vw rgb(65 73 36), 0.05vw 0.05vw 0.5vw rgb(65 73 36), 0.08vw 0.08vw 0.5vw rgb(65 73 36 / 90%), 0.11vw 0.11vw 0.5vw rgb(65 73 36 / 80%), 0.14vw 0.14vw 0 rgb(65 73 36 / 70%), 0.17vw 0.17vw 0 rgb(65 73 36 / 60%), 0.2vw 0.2vw 0 rgb(65 73 36 / 50%), 0.25vw 0.25vw 0 rgb(65 73 36 / 40%), 0.3vw 0.3vw 0 rgb(65 73 36 / 30%), 0.35vw 0.35vw 0vw rgb(65 73 36 / 20%) */
}
</style>