const state = {
    artPiecesOk: false,
    propertiesOk: false,
    collectionNamesOk: false,
    //Language
    languageId: 1,
    metricSystemId:0,
    languages:[{id:0,name:"ES"},{id:1,name:"EN"}],
    privacyPolicyLabel:["Política de privacidad", "Privacy Policy"],
    termsOfUseLabel:["Términos de uso", "Terms of Use"],
    homeButtonLabel:["Cuadros", "Artwork"],
    artPiecesButtonLabel:["Tienda", "Shop"],
    aboutButtonLabel:["Acerca de", "About"],
    contactButtonLabel:["Contacto", "Contact"],
    portfolioButtonLabel:["Portafolio", "Portfolio"],
    myOrdersButtonLabel:["Mis Ordenes", "My Orders"],
    shoppingCartButtonLabel:["Carrito de compras", "Shopping cart"],
    myOrderLabel:["Mi orden", "My order"],
    shopNow:["Comprar ahora", "Shop now"],
    seeAll:["Ver todo", "See all"],
    seeMore:["Ver más", "See more"],
    priceLabel:["Precio","Price"],
    sizeLabel: ["Tamaño", "Size"],
    printedMediumLabel: ["Tipo impresión", "Printed Medium"],
    frameLabel: ["Marco", "Frame"],
    linerLabel: ["Paspartú", "Mat"],
    inStockLabel:["Inventario disponible", "Available stock"],
    outOfStockLabel:["Liquidado", "Soldout"],
    sizeErrorMessage: ["Favor seleccionar un tamaño.", "Select a size."],
    printedMediumErrorMessage: ["Favor seleccionar un tipo de impresión.", "Select a Printed Medium."],
    frameErrorMessage: ["Favor seleccionar un marco.", "Select a Frame."],
    linerErrorMessage: ["Favor seleccionar un paspartú.", "Select a Liner."],
    sizeHint: ["Ancho x Alto", "Width x Height"],
    printedMediumHint: ["Material sobre el cual se imprime la foto.", "Print material of the photo."],
    frameHint: ["Material del marco.", "Material of the Frame."],
    linerHint: ["Espacio entre el marco y la foto.", "Space between the photo and the frame."],
    addToCartLabel:["Agregar al carrito","Add to cart"],
    orderByLabel:["Ordenar por", "Sort by"],
    collectionLabel:["Colección", "Collection"],
    collectionsLabel:["Colecciones", "Collections"],
    
    categoriesLabel:["Categorías","Categories"],
    colorsLabel:["Colores","Colours"],
    stylesLabel:["Estilos","Styles"],
    roomsLabel:["Habitaciones","Rooms"],
    themesLabel:["Temas","Themes"],
    shapesLabel:["Formas","Shapes"],
    // detailsLabel:["Detalles", "Details"],
    detailsLabel:["Vista Previa", "Preview"],
    moreDetailsLabel:["Más detalles", "More details"],
    orderSummaryLabel:["Resumen de la orden", "Order summary"],
    subtotalLabel:["Subtotal", "Subtotal"],
    shippingLabel:["Envío", "Shipping"],
    shippingRegionLabel:["Región de envío", "Shipping region"],
    pendingLabel:["Pendiente", "Pending"],
    verifyLabel:["Verificar", "Verify"],
    cartLabel:["Carrito", "Cart"],
    loginLabel:["Iniciar sesión", "Login"],
    logoutLabel:["Cerrar sesión", "Logout"],
    createAccountLabel: ["Crear cuenta", "Create account"],
    returnLabel:["Regresar", "Return"],
    continueLabel:["Continuar", "Continue"],
    nameLabel:["Nombre", "Name"],
    emailLabel:["Email", "Email"],
    addressLabel:["Dirección", "Address"],
    address2Label:["Dirección - información adicional", "Address - additional information"],
    zipCodeLabel:["Código postal","Zip code"],
    phoneLabel:["Teléfono","Phone number"],
    cityLabel:["Ciudad", "City"],
    countryLabel:["País", "Country"],
    passwordLabel:["Contraseña", "Password"],
    paymentAndShippingInformationLabel:["Información de pago y envio", "Payment and shipping information"],
    featuredCollectionsLabel:["Colecciones destacadas","Featured collections"],
    featuredCollectionLabel:["Colección destacada","Featured collection"],
    totalLabel:["Total", "Total"],
    freeLabel:["Gratis", "Free"],
    summaryLabel:["Resumen", "Summary"],
    paymentSummaryLabel:["Resumen de pago", "Payment summary"],
    amountLabel:["Cantidad", "Amount"],
    payuButtonLabel:["Pagar con PayU", "Continue to PayU"],
    skuLabel:["Sku", "Sku"],
    serialNumberLabel:["Número de serial", "Serial number"],
    startingPriceLabel:["Desde: ","From: "],
    filterLabel:["Filtrar por", "Filter by"],
    comingSoonLabel:["Próximamente", "Coming Soon"],
    newLabel:["nuevo", " new"],
    //Authentication
    app_token:null,
    buyAsGuest:false,
    //Order
    orderId:null,
    totalAmount:null,
    order:{
        ShippingName:'',
        ShippingEmail:'',
        ShippingAddress:'',
        ShippingAddress2:'',
        ShippingZipCode:'',
        ShippingCity:'Medellín',
        ShippingState:'',
        ShippingCountry:'Colombia',
        ShippingPhone:'',
        PayerName:'',
        PayerEmail:'',
        PayerAddress:'',
        PayerCity:'Medellín',
        PayerState:'',
        PayerCountry:'Colombia',
        SelectedLanguage:'',
        Currency:'COP',
        totalValue:null,
        taxesValue:0,
        shippingValue:null,
        orderDetails:[],
    },
    //Definiciones de compra
    size:[],
    printedMedium:[],
    frame:[],
    liner:[],
    shippingValues:[],
    categories:[],
    colors:[],
    styles:[],
    rooms:[],
    themes:[],
    shapes:[],
    //FiltrosFiltros
    collectionNames:[],
    categoriesNames:[],
    colorsNames:[],
    stylesNames:[],
    roomsNames:[],
    themesNames:[],
    shapesNames:[],
    priceRange:{
        min:100000,
        max:500000
    },
    collectionFilter:"",
    categoriesFilter:0,
    colorsFilter:0,
    stylesFilter:0,
    roomsFilter:0,
    themesFilter:0,
    shapesFilter:0,
    priceFilter:[],
    sizeFilter:"",

    //Ordenadores
    sortByList:["Ordenar por|Sort by", "Precio (bajo a alto)|Price (low to high)", "Precio (alto a bajo)|Price (high to low)", "Nombre (A a la Z)|Name (A to Z)", "Nombre (Z a la A)|Name (Z to A)"],
    sortBy:"Ordenar por|Sort by",
    //Galeria de fotos
    artPieces:[],
    //Shopping Cart
    shoppingCart:[
        // {
        //     title:"Prueba 5",
        //     size:1,
        //     printedMedium:3,
        //     frame:2,
        //     liner:1,
        //     quantity:3
        // },
    ],
    checkoutClick:false,
    ShowingPages:2,
    lastVisited:'',
    visitedForm:{
        title:"",
        size:null,
        frame:1,
        liner:1,
    },
    baseArtPiecesUrl:["cuadros","art-pieces"],
    baseThemeUrl:["todo","all"],
    blogButtonLabel:["Blog","Blog"]
};

const getters = {};

const actions = {
    setMutation({commit},{property:prop, with:data}){
        if(prop == 'languageId'){
            let language = 'en-US';
            if(data == 0){
                language = 'es-CO';
            }
            if(data == 1){
                language =  'en-US';
            }
			let html = document.getElementsByTagName('html');
			html[0].lang = language;
        }
        commit('mutate', {property:prop, with:data})
    },
    filter({commit},{property:prop, with:data}){
        commit('mutate', {property:prop, with:data})
    },
    addItemToCart({commit},{property:prop, with:data}){
        commit('addToCart', {property:prop, with:data})
    },
    removeFromCart({commit},{property:prop, with:data}){
        commit('removeItem', {property:prop, with:data})
    },
    setOrders({commit},{property:prop, with:data}){
        commit('orders', {property:prop, with:data})
    },
    logout({commit}){
        commit('mutate', {property:'app_token', with:null})
    },
    changeLanguage({commit}, id){
        commit('mutate', {property:'languageId', with:id})
    },
    setVisitedForm({commit},{property:prop, with:data}){
        commit('visited', {property:prop, with:data})
    }
};

const mutations = {
    mutate(state, payload) {
        state[payload.property] = payload.with;
    },
    addToCart(state, payload) {
        var shoppingCart = state.shoppingCart;
        var existingItem = shoppingCart.find(x=>x.title == payload.with.title);
        if(existingItem!=undefined){
            shoppingCart = shoppingCart.filter(x=>x.title!=payload.with.title);
        }
        shoppingCart.push(payload.with);
        state[payload.property] = shoppingCart;
    },
    orders(state, payload) {
        state.order[payload.property] = payload.with;
    },
    removeItem(state,payload){
        var shoppingCart = state.shoppingCart;
        var existingItem = shoppingCart.find(x=>x.title == payload.with.title);
        if(existingItem!=undefined){
            shoppingCart = shoppingCart.filter(x=>x.title!=payload.with.title);
        }
        state[payload.property] = shoppingCart;
    },
    visited(state,payload){
        state.visitedForm[payload.property] = payload.with;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};