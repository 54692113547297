const state = {
    pageTitle:['',''],
    pageDescription:['',''],
    collectionLabel:["Tema","Bricks, cities and towns"],
    categoryLabel:["Categoría", "Category"],
    shareLabel:["Compartir", "Share"],
    idealLabel:["ideal para todos", "ideal for all"],
    spacesLabel:["Tus espacios", "Your spaces"],
    littleHistoryLabel:["Una pequeña historia", "A little story"],
    additionalInformationLabel:["Información adicional", "Additional information"],
    reviewsLabel:["Reseñas", "Reviews"],
    moreLabel:["más", "more"],
    collectionsForYouLabel:["Collecciones para ti", "Collections for you"],
    exifLabel:["Fecha|Date","Ubicación|Location","Velocidad del obturador|Shutter Speed","Apertura|Aperture","Velocidad ISO|ISO Speed","Distancia Focal|Focal Length","Lente|Lens","Modelo de la Cámara|Camera Model"],
    simulatorTitle1: ["¿Quieres probar cómo","Do you want to see"],
    simulatorTitle2: ["se vería esta foto en tu casa?","this photo in your home?"],
    simulatorTitle3: ["Haz clic en iniciar y pruébalo.","Click on start to try out."],
    simulatorButton: ["Iniciar","Start"],

    spacesBackgroundImages:[
        require('../../assets/ArtDetails/Products_Desktop-Recovered_05.jpg'),
        require('../../assets/ArtDetails/Products_Desktop_01.jpg'),
        require('../../assets/ArtDetails/Products_Desktop_02.jpg'),
        require('../../assets/ArtDetails/Products_Desktop_03.jpg'),
        require('../../assets/ArtDetails/Products_Desktop_04.jpg'),
        require('../../assets/ArtDetails/Products_Desktop_05.jpg'),
        require('../../assets/ArtDetails/Products_Desktop_06.jpg'),
        require('../../assets/ArtDetails/Products_Desktop_07.jpg'),
        require('../../assets/ArtDetails/Products_Desktop_08.jpg'),
        require('../../assets/ArtDetails/Products_Desktop_09.jpg'),
        require('../../assets/ArtDetails/Products_Desktop_10.jpg'),
        require('../../assets/ArtDetails/Products_Desktop_11.jpg')
    ],

    collectionId:0,
    historySelected:true,
    infoSelected:false,
    reviewSelected:false,

    sizes:[{id:"",name:""}],
    printedMediums:[{id:"",name:""}],
    frames:[{id:"",name:""}],
    liners:[{id:"",name:""}],
    dataOk:false,
    soldDataOk:false,

    form:{
        title:"",
        size:null,
        printedMedium:null,
        frame:null,
        liner:null,
        quantity:1,
        unitPrice:null,
    },
    sizeError:false,
    printedMediumError:false,
    frameError:false,
    linerError:false,
    quantityError:false,
    display:{},
    error:false,
    
    // bugColor:"rgba(186, 19, 30, 0.8)",
    // butterflyColor:"rgba(140, 108, 14, 0.7)",
    // featherColor:"rgba(102, 26, 69, 1)",
    // ghostColor:"rgba(31, 14, 9, 0.7)",
    // globeColor:"rgba(118, 91, 140, 1)",
    // kiwiColor:"rgba(95, 148, 92, 1)",
    // cityColor:"rgba(176, 117, 93, 1)",
    // infinityColor:"rgba(109, 129, 148, 1)",
    // winterColor:"rgba(80, 161, 222, 1)",
    // springColor:"rgba(56, 214, 95, 1)",
    // summerColor:"rgba(252, 252, 111, 1)",
    // autumnColor:"rgba(219, 136, 35, 1)",

    snackbar:false,
    text: '',

    selectedImage:'',
    selectedImageIndex: 0,
    firstFrameSelect:false,
    firstLinerSelect:false,
};

const getters = {
    imageSizeWidth(){
        var windowWidth = Math.round(0.721153846153846*(this.widthSize)-238.461538461538);
        return windowWidth;
    },
    imageSizeHeight(){
        var windowHeight = Math.round(0.5*(this.imageSizeWidth)+200);
        return windowHeight
    },
    maxImageSizeHeight(){
        var windowHeight = Math.round(0.319711538461538*(this.widthSize)+72.6153846153846);
        return windowHeight
    },
    isNew(state ){
        return state.display.isNew;
    },
    isComingSoon(state){
        return state.display.price == null
    },
    exifDate(state, getters){
        var stringDate = state.display.exif.split("|")[0].split("-")
        var date = new Date(stringDate.toString())
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString(getters.languageString,options);
    },
    languageString(state, getters, rootState){
        if(rootState.shoppingCart.languageId == 0){
            return 'es-ES';
        }
        if(rootState.shoppingCart.languageId == 1){
            return 'en-US';
        }
        return 'es-US'
    },
    getCollectionImage(state, getters, rootState, {idCollection,selectedImage,direction}){
        var artPieces = getters.getArtPieces(idCollection,direction);
        var image = rootState.shoppingCart.images.find(x=>x.idArtPiece == artPieces[selectedImage].id)
        return image.base64
    },
    getArtPieces(state, getters, rootState, {idCollection,direction="Landscape"}){
        return rootState.shoppingCart.artPieces.filter(x=>x.idCollection == idCollection && x.orientation == direction);
    },
    selectMuckup(state, getters, rootState){
        return rootState.shoppingCart.images.find(x=>x.idArtPiece == state.selectedImage.idArtPiece && x.idImageType == 3);
    },
    getSizeIndex(state, getters){
        console.log(getters)
        var sizes = getters.artPieceSizes
        return sizes.indexOf(sizes.find(x=>x.id == state.form.size))
    },
    getMaxProductionBySize(state, getters, rootState){
        var soldUnitsNumber = 0;
        var soldUnits = rootState.shoppingCart.soldUnits.find(x=>x.title == state.form.title)
        if(soldUnits != undefined){
            var soldIndex = soldUnits.sizes.indexOf(state.form.size.toString());
            if(soldIndex != -1){
                soldUnitsNumber = soldUnits.soldUnits[soldIndex];
            }
        }
        return parseInt(state.display.productionQuantityBySize.split("|")[getters.getSizeIndex] - soldUnitsNumber)
    },
    getOutOfStock(state, getters){
        return state.display.outOfStock.split('|')[getters.getSizeIndex] == "true" || getters.getMaxProductionBySize < 1
    },
    artPieceSizes(state, getters, rootState){
        return rootState.shoppingCart.size.filter(x=>state.display.sizes.split("|").includes(x.id.toString())).map(x=>{return {id:x.id,name:x.name.split("|")[rootState.shoppingCart.metricSystemId]}})
    },
    artPiecesPrintedMediums(state, getters, rootState){
        return rootState.shoppingCart.printedMedium.filter(x=>state.display.printedMediums.split("|").includes(x.id.toString())).map(x=>{return {id:x.id,name:x.name.split("|")[rootState.shoppingCart.languageId]}})
    },
    artPiecesFrames(state, getters, rootState){
        return rootState.shoppingCart.frame.filter(x=>state.display.frames.split("|").includes(x.id.toString())).map(x=>{return {id:x.id,name:x.name.split("|")[rootState.shoppingCart.languageId]}})
    },
    artPiecesLiners(state, getters, rootState){
        return rootState.shoppingCart.liner.filter(x=>state.display.liners.split("|").includes(x.id.toString())).map(x=>{return {id:x.id,name:x.name.split("|")[rootState.shoppingCart.languageId]}})
    },
    getPrice(state, getters, rootState){
        var size = state.form.size!=null? rootState.shoppingCart.size.find(x=>x.id == state.form.size) : null
        var printedMediumPrice =  state.form.printedMedium!=null? rootState.shoppingCart.printedMedium.find(x=>x.id == state.form.printedMedium).price : 0
        var framePrice =  state.form.frame!=null? rootState.shoppingCart.frame.find(x=>x.id == state.form.frame).price : 0
        var linerPrice =  state.form.liner!=null? rootState.shoppingCart.liner.find(x=>x.id == state.form.liner).price : 0
        var widthXHeight = size!=null? size.width * size.height: 0
        var sizePrice = size!=null? size.price : 0
        return Math.round(state.display.price + sizePrice*widthXHeight + printedMediumPrice*widthXHeight + framePrice*widthXHeight + linerPrice*widthXHeight);
    },
    displayImages(state, getters, rootState){
        var images = rootState.shoppingCart.images.filter(x=>x.idArtPiece == state.display.id && (x.idImageType == 2 || x.idImageType == 4 || x.idImageType == 5));
        if(state.firstFrameSelect){
            images = images.filter(x=>x.idFrame == state.form.frame || x.idFrame == 1);
        }
        if(state.firstLinerSelect){
            images = images.filter(x=>x.idLiner == state.form.liner || x.idFrame == 1)
        }
        return images 
    }
};

const actions = {
    setMutation({commit},{property:prop, with:data}){
        commit('mutate', {property:prop, with:data})
    },
    setForm({commit},data) {
        commit('changeForm', {with:data})
    },
    setFormProperties({commit},{property:prop, with:data}) {
        commit('changeFormProperties', {property:prop, with:data})
    },
    selectImage({ commit}, {image,index}){
        commit("selectImage", {image,index})
    },
    changeImage({ commit, getters }, {direction,selection}){
        var images = getters.displayImages;
        commit("changeImage", {direction,selection,images})
    },
    validations({ commit, getters }){
        var maxProductionBySize = getters.getMaxProductionBySize;
        commit("validations", maxProductionBySize)
    },
};

const mutations = {
    mutate(state, payload) {
        state[payload.property] = payload.with;
    },
    changeForm(state, payload) {
        state.form = payload.with;
    },
    changeFormProperties(state, payload) {
        state.form[payload.property] = payload.with;
    },
    selectImage(state, {image,index}){
        state.selectedImageIndex=index;
        state.selectedImage = image;
    },
    changeImage(state, {direction,selection, images}){
        var currentImage = state.selectedImageIndex;
        if(direction=="back"){
            if(currentImage==0)
                currentImage = images.length-1;
            else
                currentImage -= 1
        }
        if(direction=="forward"){
            if(currentImage==images.length-1)
                currentImage = 0;
            else
                currentImage += 1
        }
        state.selectedImageIndex=currentImage;
        state[selection] = images[currentImage];
    },
    validations(state, maxProductionBySize){
        state.error=false;
        if(state.form.size==null){
            state.sizeError=true;
            state.error=true;
        }
        else{
            state.sizeError=false;
        }
        if(state.form.printedMedium==null){
            state.printedMediumError=true;
            state.error=true;
        }
        else{
            state.printedMediumError=false;
        }
        if(state.form.frame==null){
            state.frameError=true;
            state.error=true;
        }
        else{
            state.frameError=false;
        }
        if(state.form.liner==null){
            state.linerError=true;
            state.error=true;
        }
        else{
            state.linerError=false;
        }
        if(state.form.quantity<=0){
            state.form.quantity = 1;
            state.quantityError=true;
            state.error=true;
        }
        else{
            state.quantityError=false;
        }
        if(state.form.quantity > maxProductionBySize){
            state.form.quantity = maxProductionBySize;
            state.quantityError=true;
            state.error=true;
        }
        else{
            state.quantityError=false;
        }
        console.log('validations',state.error)
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};