import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
            primary: '#2F2E2E', // #E53935
            secondary: '#D79A2B', // #FFCDD2
            accent: '#D79A2B', // #3F51B5
            },
        },
    },
});
